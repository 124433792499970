$bodyFont: 'Roboto';
$logoFont: 'Roboto Condensed';
$defaultSpacingSize: 2.5em;

//================
// Bootstrap
//================
.row {
  display: flex;
  margin: 0 -15px;
}

.col {
  flex: 1;
  padding: 0 15px;
}

table, th, td {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-collapse: collapse;
  border-spacing: 0;
  background-image: none;
  text-align: left;
  vertical-align: top;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

//================
// INVOICE
//================
.invoice-wrapper {
    margin: 0;
    padding: 0;
    font-family: $bodyFont;
    font-size: 16px;

    @media print {
        font-size: 16px;
        -webkit-print-color-adjust: exact !important;
    }
}
.invoice {
    background: #fff;
    border: 1px solid #ccc;
    padding: 50px 70px;
    width: 794px;
    height: 1123px;

    display: flex;
    flex-direction: column;

    @media print {
        box-sizing: border-box;
        border: 0;
        margin: 0;
        padding: 50px 60px;
        width: 100%;
        max-width: 100%;
        height: 100vh;
    }

    // .invoice__header
    &__header {
        padding-bottom: 1.5*$defaultSpacingSize;

        @media print {
            padding-bottom: 1*$defaultSpacingSize;
        }
    }

    // .invoice__body
    &__body {
        flex: 1 0 auto;
    }

    // .invoice__body
    &__footer {
        padding-top: $defaultSpacingSize;
        //align-self: flex-end;
        
        @media print {
            font-size: 14px;
        }
    }

    // .invoice__info
    &__info {
        margin-bottom: 2*$defaultSpacingSize;

        @media print {
            margin-bottom: 1.5*$defaultSpacingSize;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    // .invoice__number
    &__number {
        display: block;
        text-align: right;
        text-transform: uppercase;
        font-family: $bodyFont;
        font-weight: 700;
        font-size: 1.25em;
        margin-bottom: $defaultSpacingSize;

        &:last-child {
            margin-bottom: 0;
        }
    }

    // .invoice__recipient
    &__recipient {
        margin-bottom: 0.5*$defaultSpacingSize;

        &:last-child {
            margin-bottom: 0;
        }
    }

    // .invoice__table
    &__table {
        width: 100%;

        thead {
            tr {
                background: #000;
                color: #fff;
                text-transform: uppercase;
            }
        }

        tbody {
            th {

            }
        }

        tfoot {
            th {
                text-align: right;
                text-transform: uppercase;
            }
        }


        td, th {
            padding: 7.5px 7.5px;
            border: 1px solid #000;
            line-height: 1.4;
            font-size: 0.813em;

            strong {
                font-weight: 700;
            }
        }

        th {
            font-weight: 700;
        }
    }

    // .invoice__col
    &__col {
        // .invoice__col--amount
        &--amount {
            width: 120px;

            @media print {
                width: 100px;
            }
        }

        // .invoice__col--rate
        &--rate {
            width: 115px;

            @media print {
                width: 110px;
            }
        }

        // .invoice__col--rate
        &--total {
            width: 115px;

            @media print {
                width: 120px;
            }
        }
    }
}

//================
// LOGO
//================
.logo {

    // .logo__main
    &__main {
        font-family: $logoFont;
        font-size: 2em;
        font-weight: 700;
        line-height: 1.15;
        text-transform: uppercase;

        @media print {
            font-size: 2em;
        }
    }

    // .logo__sub
    &__sub {
        font-size: 0.875em;
    }
}

//================
// HEADING
//================
.heading {
    display: block;
    font-family: $bodyFont;
    font-weight: 700;
    margin: 0 0 20px;

    &:last-child {
        margin-bottom: 0;
    }

    // .heading--lv3
    &--lv3 {
        font-size: 1.25em;
    }

    // .heading--lv4
    &--lv4 {
        font-size: 1.125em;
    }

    // .heading--uppercase
    &--uppercase {
        text-transform: uppercase;
    }

    // .heading--condensed
    &--condensed {
        font-family: $logoFont;
    }

    // .heading--no-margin
    &--no-margin {
        margin-bottom: 0;
    }
}

// .text-box
.text-box {
    p, table, ul, ol {
        font-size: 0.813em;
        margin: 0 0 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    // .text-box--small
    &--small {
        p, table, ul, ol {
            font-size: 0.75em;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}