html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: "Roboto", Arial, sans-serif;
}

#root {
  width: 100%;
  height: 100%;
}
